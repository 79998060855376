<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <!-- products -->
          <b-col cols="12">
            <b-card title="Update Products">
              <b-row>
                <b-col cols="12">
                  <div style="position: relative">
                    <b-row>
                      <b-col cols="10">
                        <b-form-input
                          id="searchText"
                          type="search"
                          :state="bundData.variants.length == 0 ? false : true"
                          v-model="searchText"
                          placeholder="Search For Add Item"
                        />
                      </b-col>
                      <b-col cols="2">
                        <b-button
                          @click="searchResult(searchText)"
                          variant="outline-primary"
                          >Search</b-button
                        >
                      </b-col>
                      <b-alert
                        variant="warning"
                        style="margin: 0.438rem 1rem"
                        :show="noResult"
                      >
                        <div class="alert-body">No Result Found.</div>
                      </b-alert>
                      <b-col
                        style="
                          position: absolute;
                          z-index: 9999;
                          padding: 0.438rem 1rem;
                          top: 35px;
                          height: 380px !important;
                          overflow: scroll;
                          scroll-padding: 0px !important;
                          padding-right: 0;
                        "
                        v-if="resultShow"
                        cols="9"
                      >
                        <b-list-group>
                          <b-list-group-item
                            @click="addProduct(item.id, item.name, item.price)"
                            v-for="(item, index) in productsSR"
                            :key="index"
                          >
                            <feather-icon icon="" class="mr-75" />
                            <p style="font-weight: 600">{{ item.name }}</p>
                            <small style="padding-left: 10px"
                              >Price: {{ item.price }}</small
                            >
                          </b-list-group-item>
                        </b-list-group>
                      </b-col>
                      <b-col cols="12">
                        <b-row>
                          <b-col cols="12">
                            <h2 class="mr-3 pt-2">Items List:</h2>
                            <!-- Row Loop -->
                            <b-row
                              v-if="bundData.variants.length"
                              v-for="(item, index) in bundData.variants"
                              :key="index"
                            >
                              <b-col cols="12">
                                <hr />
                              </b-col>
                              <b-col class="pl-5" md="3">
                                <h4 style="line-height: 2.5rem">
                                  {{ item.name }}
                                </h4>
                                <small style="padding-left: 10px"
                                  >Price: {{ item.price }}</small
                                >
                              </b-col>
                              <b-col md="1">
                                <b-form-group
                                  label="Quantity"
                                  label-for="Quantity"
                                >
                                  <validation-provider
                                    #default="{ errors }"
                                    name="Quantity"
                                  >
                                    <b-form-input
                                      min="1"
                                      :id="'Quantity' + index"
                                      type="number"
                                      v-model="item.quantity"
                                      @input="recalcCost()"
                                      :state="errors.length > 0 ? false : null"
                                      placeholder="15"
                                    />
                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <!-- profit -->
                              <b-col v-if="!item.gift" md="1">
                                <b-form-group label="Profit" label-for="profit">
                                  <validation-provider
                                    #default="{ errors }"
                                    name="profit"
                                    rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                                  >
                                    <b-form-input
                                      id="profit"
                                      v-model="item.profit"
                                      @input="changeProfit(index)"
                                      :state="errors.length > 0 ? false : null"
                                      placeholder="profit"
                                    />
                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                    <small
                                      class="text-danger"
                                      v-show="profit_percent"
                                      >profit must be between 0 - 100</small
                                    >
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <!-- amount -->
                              <b-col v-if="!item.gift" md="1">
                                <b-form-group
                                  label="discount"
                                  label-for="discount"
                                >
                                  <validation-provider
                                    #default="{ errors }"
                                    name="discount"
                                    rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                                  >
                                    <b-form-input
                                      id="discount"
                                      v-model="item.discount"
                                      @input="changeAmount(index)"
                                      :state="errors.length > 0 ? false : null"
                                      placeholder="discount"
                                    />
                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                    <small
                                      class="text-danger"
                                      v-show="discount_percent"
                                      >Discount must be between 0 - 99</small
                                    >
                                    <small
                                      class="text-danger"
                                      v-show="discount_fixed"
                                      >Discount must be less than price</small
                                    >
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="1" class="text-center">
                                <label style="font-size: 15px" for="Master"
                                  >Master</label
                                >
                                <input
                                  type="radio"
                                  name="Master"
                                  @click="removeGift(index)"
                                  :id="item.variant_id"
                                  :value="{
                                    id: item.variant_id,
                                    name: item.name,
                                  }"
                                  v-model="masterVariant_id"
                                />
                              </b-col>
                              <b-col
                                v-show="masterVariant_id.id !== item.variant_id"
                                md="1"
                                class="text-center"
                              >
                                <label
                                  for="Gift"
                                  style="
                                    font-size: 15px;
                                    margin-right: 15px;
                                    margin-left: 10px;
                                  "
                                  >Gift</label
                                >
                                <input
                                  type="checkbox"
                                  @change="giftClick(index)"
                                  :id="item.variant_id"
                                  value="0"
                                  v-model="item.gift"
                                />
                              </b-col>
                              <b-col
                                v-if="!item.gift"
                                md="3"
                                class="text-center"
                              >
                                <label
                                  style="
                                    font-size: 15px;
                                    margin-right: 15px;
                                    margin-left: 10px;
                                  "
                                  >Sale Price / per item</label
                                ><br />
                                <label
                                  style="
                                    font-size: 15px;
                                    margin-right: 15px;
                                    margin-left: 10px;
                                  "
                                >
                                  {{ item.salePrice }}
                                </label>
                              </b-col>
                              <!-- Remove Button -->
                              <b-col md="12" class="mb-50">
                                <b-button
                                  block
                                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                  variant="outline-danger"
                                  class="mt-0 mt-md-2"
                                  @click="removeItem(index)"
                                >
                                  <feather-icon icon="XIcon" class="mr-25" />
                                  <span>Remove</span>
                                </b-button>
                              </b-col>
                              <b-col cols="12">
                                <hr />
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col class="mt-2" cols="12">
            <h3>Totla: {{ total }}</h3>
            <h3>Offer Price :{{ offerPrice }}</h3>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
          <!--submit button -->
          <b-col>
            <b-button
              size="lg"
              variant="primary"
              :disabled="disabled_submit"
              type="submit"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>

import BCardCode from '@core/components/b-card-code'
import { heightTransition } from '@core/mixins/ui/transition'
import { required, integer } from '@validations'
import { VueEditor } from 'vue2-editor'
export default {
  components: {
    BCardCode,
    VueEditor,
  },
  
  mixins: [heightTransition],
  data() {
    return {
      showDismissibleAlert: false,
      noResult: false,
      resultShow: false,
      productsSR: [],
      profit_percent: false,
      discount_percent: false,
      discount_fixed: false,
      disabled_submit: true,
      masterVariant_id: {
        id: '',
        name: '',
      },
      searchText: '',
      cost: '',
      cost2: '',
      total: '',
      offerPrice: '',
      bundleData: {
        variants: [],
      },
      bundData: {
        masterVariant_id: null,
        variants: [],
      },
      errors_back: [],
      required,
      integer,
    }
  },
  created() {
    this.id = this.$route.params.id
    axios
      .get('bundles/' + this.id)
      .then((result) => {
        this.bundData.length = 0
        this.bundleData = result.data.data
        for (let index in this.bundleData.variants) {
          if (this.bundleData.variants[index].translation != null) {
            this.bundData.variants.push({
              quantity: this.bundleData.variants[index].bundle_quantity,
              price: this.bundleData.variants[index].original_price,
              name: this.bundleData.variants[index].translation.name,
              variant_id: this.bundleData.variants[index].id,
              gift: this.bundleData.variants[index].gift,
              profit: this.bundleData.variants[index].profit,
              discount: this.bundleData.variants[index].discount,
              salePrice: '',
            })
          } else {
            this.bundData.variants.push({
              quantity: this.bundleData.variants[index].bundle_quantity,
              price: this.bundleData.variants[index].original_price,
              name: 'undefinded',
              variant_id: this.bundleData.variants[index].id,
              gift: this.bundleData.variants[index].gift,
              profit: this.bundleData.variants[index].profit,
              discount: this.bundleData.variants[index].discount,
              salePrice: '',
            })
          }
          if (this.bundleData.master == this.bundleData.variants[index].id) {
            this.masterVariant_id.id = this.bundleData.variants[index].id
            this.masterVariant_id.name =
              this.bundleData.variants[index].translation.name
          }
          this.bundData.masterVariant_id = this.masterVariant_id.id
        }

        this.cost = 0
        this.cost2 = 0
        for (let index in this.bundData.variants) {
          if (this.bundData.variants[index].gift == false) {
            if (this.bundleData.type == 'percentage') {
              const percent =
                (parseFloat(this.bundData.variants[index].price) *
                  parseFloat(this.bundData.variants[index].discount)) /
                100
              const percentProfite =
                (this.bundData.variants[index].price *
                  this.bundData.variants[index].profit) /
                100
              this.bundData.variants[index].salePrice =
                parseFloat(this.bundData.variants[index].price) -
                percent +
                percentProfite
            } else {
              this.bundData.variants[index].salePrice =
                parseFloat(this.bundData.variants[index].price) -
                parseFloat(this.bundData.variants[index].discount) +
                parseFloat(this.bundData.variants[index].profit)
            }
            const qun = parseInt(this.bundData.variants[index].quantity)
            const pri = parseFloat(this.bundData.variants[index].salePrice)
            this.cost += qun * pri
            this.offerPrice = this.cost
          }
          const qun2 = parseInt(this.bundData.variants[index].quantity)
          const pri2 = parseFloat(this.bundData.variants[index].price)
          this.cost2 += qun2 * pri2
          this.total = this.cost2
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.bundData.masterVariant_id = this.masterVariant_id.id
          const bund = {
            masterVariant_id: '',
            variants: [],
          }
          bund.masterVariant_id = this.bundData.masterVariant_id
          for (let ind in this.bundData.variants) {
            bund.variants.push({
              variant_id: this.bundData.variants[ind].variant_id,
              quantity: this.bundData.variants[ind].quantity,
              gift: this.bundData.variants[ind].gift,
              profit: this.bundData.variants[ind].profit,
              discount: this.bundData.variants[ind].discount,
            })
          }
          for (let ind in bund.variants) {
            if (bund.variants[ind].gift == true) {
              bund.variants[ind].profit = 0
              bund.variants[ind].discount = 0
            }
          }

          axios
            .post('bundles/' + this.id + '/sync-variants', bund)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.back(1)
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    searchResult(text) {
      this.noResult = false
      axios
        .get('agency/variants/' + text)
        .then((result) => {
          const data = result.data.data

          this.productsSR.length = 0
          for (let index in data) {
            if (data[index].translation != null) {
              this.productsSR.push({
                id: data[index].id,
                name: data[index].translation.name,
                price: data[index].original_price,
              })
            } else {
              this.productsSR.push({
                id: data[index].id,
                name: 'undefinded',
                price: data[index].original_price,
              })
            }
          }
          if (this.productsSR.length > 0) {
            this.resultShow = true
          } else {
            this.noResult = true
            this.resultShow = false
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    addProduct(id, name, price) {
      this.resultShow = false
      if (this.bundData.variants.length == 0) {
        this.masterVariant_id = {
          id: id,
          name: name,
        }
      }
      this.disabled_submit = true
      this.bundData.variants.push({
        variant_id: id,
        price: price,
        quantity: '1',
        name: name,
        gift: false,
        profit: '',
        discount: '',
      })
      // this.bundData.variants = [...new Map(this.bundData.variants.map(item => [item.id, item])).values()]
      this.cost = 0
      this.cost2 = 0
      for (let index in this.bundData.variants) {
        if (this.bundData.variants[index].gift == false) {
          if (this.bundleData.type == 'percentage') {
            const percent =
              (parseFloat(this.bundData.variants[index].price) *
                parseFloat(this.bundData.variants[index].discount)) /
              100
            const percentProfite =
              (this.bundData.variants[index].price *
                this.bundData.variants[index].profit) /
              100
            this.bundData.variants[index].salePrice =
              parseFloat(this.bundData.variants[index].price) -
              percent +
              percentProfite
          } else {
            this.bundData.variants[index].salePrice =
              parseFloat(this.bundData.variants[index].price) -
              parseFloat(this.bundData.variants[index].discount) +
              parseFloat(this.bundData.variants[index].profit)
          }
          const qun = parseInt(this.bundData.variants[index].quantity)
          const pri = parseFloat(this.bundData.variants[index].salePrice)
          this.cost += qun * pri
          this.offerPrice = this.cost
        }
        const qun2 = parseInt(this.bundData.variants[index].quantity)
        const pri2 = parseFloat(this.bundData.variants[index].price)
        this.cost2 += qun2 * pri2
        this.total = this.cost2
      }
      this.productsSR.length = 0
    },
    recalcCost() {
      this.cost = 0
      this.cost2 = 0
      for (let index in this.bundData.variants) {
        if (this.bundData.variants[index].gift == false) {
          if (this.bundleData.type == 'percentage') {
            const percent =
              (parseFloat(this.bundData.variants[index].price) *
                parseFloat(this.bundData.variants[index].discount)) /
              100
            const percentProfite =
              (this.bundleData.variants[index].price *
                this.bundleData.variants[index].profit) /
              100
            this.bundData.variants[index].salePrice =
              parseFloat(this.bundData.variants[index].price) -
              percent +
              percentProfite
          } else {
            this.bundData.variants[index].salePrice =
              parseFloat(this.bundData.variants[index].price) -
              parseFloat(this.bundData.variants[index].discount) +
              parseFloat(this.bundData.variants[index].profit)
          }
          const qun = parseInt(this.bundData.variants[index].quantity)
          const pri = parseFloat(this.bundData.variants[index].salePrice)
          this.cost += qun * pri
          this.offerPrice = this.cost
        }
        const qun2 = parseInt(this.bundData.variants[index].quantity)
        const pri2 = parseFloat(this.bundData.variants[index].price)
        this.cost2 += qun2 * pri2
        this.total = this.cost2
      }
    },
    removeGift(index) {
      this.bundData.variants[index].gift = false
    },
    giftClick(index) {
      if (this.bundData.variants[index].gift == true) {
        if (
          this.bundData.variants[index].salePrice != null &&
          this.bundData.variants[index].salePrice != ''
        ) {
          const pri = parseFloat(this.bundData.variants[index].salePrice)
          const qun = parseInt(this.bundData.variants[index].quantity)
          this.cost -= qun * pri
          this.offerPrice = this.cost
        }
        this.bundData.variants[index].salePrice = ''
      }
      this.bundData.variants[index].profit = ''
      this.bundData.variants[index].discount = ''
    },
    closeOpenSearch() {
      if (this.resultShow == true) {
        this.resultShow = false
      }
    },
    changeProfit(index) {
      if (this.bundData.value == 'percentage') {
        if (
          this.bundData.variants[index].profit >= 0 &&
          this.bundData.variants[index].profit <= 100
        ) {
          if (
            this.bundData.variants[index].profit != '' &&
            this.bundData.variants[index].profit != null &&
            this.bundData.variants[index].discount != '' &&
            this.bundData.variants[index].discount != null
          ) {
            if (this.bundleData.type == 'percentage') {
              const percent =
                (this.bundData.variants[index].price *
                  this.bundData.variants[index].discount) /
                100
              const percentProfite =
                (this.bundData.variants[index].price *
                  this.bundData.variants[index].profit) /
                100
              this.bundData.variants[index].salePrice =
                this.bundData.variants[index].price - percent + percentProfite
            } else {
              this.bundData.variants[index].salePrice =
                this.bundData.variants[index].price -
                this.bundData.discount +
                parseFloat(this.bundData.variants[index].profit)
            }
            this.disabled_submit = false
          } else {
            this.disabled_submit = true
            this.bundData.variants[index].salePrice = 0
          }
          this.cost = 0
          for (let ind in this.bundData.variants) {
            const qun = parseInt(this.bundData.variants[ind].quantity)
            const pri = parseFloat(this.bundData.variants[ind].salePrice)
            this.cost += qun * pri
            this.offerPrice = this.cost
          }
          this.profit_percent = false
        } else {
          this.profit_percent = true
          this.disabled_submit = true
        }
      } else {
        if (
          this.bundData.variants[index].profit != '' &&
          this.bundData.variants[index].profit != null &&
          this.bundData.variants[index].discount != '' &&
          this.bundData.variants[index].discount != null
        ) {
          if (this.bundleData.type == 'percentage') {
            const percent =
              (this.bundData.variants[index].price *
                this.bundData.variants[index].discount) /
              100
            const percentProfite =
              (this.bundleData.variants[index].price *
                this.bundleData.variants[index].profit) /
              100
            this.bundData.variants[index].salePrice =
              this.bundData.variants[index].price - percent + percentProfite
          } else {
            this.bundData.variants[index].salePrice =
              this.bundData.variants[index].price -
              this.bundData.discount +
              parseFloat(this.bundData.variants[index].profit)
          }
          this.disabled_submit = false
        } else {
          this.disabled_submit = true
          this.bundData.variants[index].salePrice = 0
        }
        this.cost = 0
        for (let ind in this.bundData.variants) {
          const qun = parseInt(this.bundData.variants[ind].quantity)
          const pri = parseFloat(this.bundData.variants[ind].salePrice)
          this.cost += qun * pri
          this.offerPrice = this.cost
        }
      }
    },
    changeAmount(index) {
      if (this.bundleData.type == 'percentage') {
        if (
          this.bundData.variants[index].discount >= 0 &&
          this.bundData.variants[index].discount < 100
        ) {
          if (
            this.bundData.variants[index].profit != '' &&
            this.bundData.variants[index].profit != null &&
            this.bundData.variants[index].discount != '' &&
            this.bundData.variants[index].discount != null
          ) {
            if (this.bundleData.type == 'percentage') {
              const percent =
                (parseFloat(this.bundData.variants[index].price) *
                  parseFloat(this.bundData.variants[index].discount)) /
                100
              const percentProfite =
                (this.bundData.variants[index].price *
                  this.bundData.variants[index].profit) /
                100
              this.bundData.variants[index].salePrice =
                parseFloat(this.bundData.variants[index].price) -
                percent +
                percentProfite
            } else {
              this.bundData.variants[index].salePrice =
                parseFloat(this.bundData.variants[index].price) -
                this.bundData.discount +
                parseFloat(this.bundData.variants[index].profit)
            }
            this.disabled_submit = false
          } else {
            this.disabled_submit = true
            this.bundData.variants[index].salePrice = 0
          }
          this.cost = 0
          for (let ind in this.bundData.variants) {
            const qun = parseInt(this.bundData.variants[ind].quantity)
            const pri = this.bundData.variants[ind].salePrice
            this.cost += qun * pri
            this.offerPrice = this.cost
          }
          this.discount_percent = false
        } else {
          this.discount_percent = true
          this.disabled_submit = true
        }
      } else {
        if (
          this.bundData.variants[index].discount <
          this.bundData.variants[index].price
        ) {
          if (
            this.bundData.variants[index].profit != '' &&
            this.bundData.variants[index].profit != null &&
            this.bundData.variants[index].discount != '' &&
            this.bundData.variants[index].discount != null
          ) {
            if (this.bundleData.type == 'percentage') {
              const percent =
                (parseFloat(this.bundData.variants[index].price) *
                  parseFloat(this.bundData.variants[index].discount)) /
                100
              const percentProfite =
                (this.bundleData.variants[index].price *
                  this.bundleData.variants[index].profit) /
                100
              this.bundData.variants[index].salePrice =
                parseFloat(this.bundData.variants[index].price) -
                percent +
                percentProfite
            } else {
              this.bundData.variants[index].salePrice =
                parseFloat(this.bundData.variants[index].price) -
                this.bundData.discount +
                parseFloat(this.bundData.variants[index].profit)
            }
            this.disabled_submit = false
          } else {
            this.disabled_submit = true
            this.bundData.variants[index].salePrice = 0
          }
          this.cost = 0
          for (let ind in this.bundData.variants) {
            const qun = parseInt(this.bundData.variants[ind].quantity)
            const pri = this.bundData.variants[ind].salePrice
            this.cost += qun * pri
            this.offerPrice = this.cost
          }
          this.discount_percent = false
        } else {
          this.discount_percent = true
          this.disabled_submit = true
        }
      }
    },
    removeItem(index) {
      if (this.bundData.variants.length > 0) {
        this.bundData.variants.splice(index, 1)
        this.cost = 0
        this.cost2 = 0
        this.disabled_submit = false
        for (let index in this.bundData.variants) {
          if (this.bundData.variants[index].gift == false) {
            if (this.bundleData.type == 'percentage') {
              const percent =
                (parseFloat(this.bundData.variants[index].price) *
                  parseFloat(this.bundData.variants[index].discount)) /
                100
              const percentProfite =
                (this.bundleData.variants[index].price *
                  this.bundleData.variants[index].profit) /
                100
              this.bundData.variants[index].salePrice =
                parseFloat(this.bundData.variants[index].price) -
                percent +
                percentProfite
            } else {
              this.bundData.variants[index].salePrice =
                parseFloat(this.bundData.variants[index].price) -
                parseFloat(this.bundData.variants[index].discount) +
                parseFloat(this.bundData.variants[index].profit)
            }
            const qun = parseInt(this.bundData.variants[index].quantity)
            const pri = parseFloat(this.bundData.variants[index].salePrice)
            this.cost += qun * pri
            this.offerPrice = this.cost
          }
          const qun2 = parseInt(this.bundData.variants[index].quantity)
          const pri2 = parseFloat(this.bundData.variants[index].price)
          this.cost2 += qun2 * pri2
          this.total = this.cost2
        }
      }
    },
  },
  isString(value) {
    return typeof value === 'string' || value instanceof String
  },
}
</script>

<style lang="scss">

@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
.repeater-form {
  transition: 0.35s height;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}
.app-auto-suggest {
  position: relative;
}
.auto-suggest-suggestions-list {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 6px;
  position: absolute;
  top: calc(100% + 1rem);
  background-color: white;
  width: 100%;
}
.suggestion-group-title {
  font-weight: 500;
  padding: 0.75rem 1rem 0.25rem;
}
.suggestion-group-suggestion {
  padding: 0.75rem 1rem;
}
.suggestion-current-selected {
  background-color: $body-bg;
  .dark-layout & {
    background-color: $theme-dark-body-bg;
  }
}
</style>
